import { useState } from 'react';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useBeforeRender } from '../../../../hooks/useBeforeRender';
import { Offcanvas } from 'react-bootstrap';
import { Flex, View, Link, DialogContainer, Dialog, Heading, Content } from '@adobe/react-spectrum';
import { Plus, Pen } from 'react-bootstrap-icons';
import { FilterIcon } from '../../../../components/protected/Icons/IconsLib';
import ActivityStatusFilterComponent from '../../../../components/protected/layout-components/FilterComponent/ActivityStatusFilter/ActivityStatusFilterComponent';
import ActivityTypeFilterComponent from '../../../../components/protected/layout-components/FilterComponent/ActivityTypeFilter/ActivityTypeFilterComponent';
import UsersFilterComponent from '../../../../components/protected/layout-components/FilterComponent/UsersFilter/UsersFilterComponent';
import { useTranslation } from 'react-i18next';
import styles from './filter_component.module.css';

interface IFilterComponentProps {
  onFilterChange: (filterParams: {}) => void;
  filterParams: any;
  filterStyle?: any;
}

const FilterComponent: React.FC<IFilterComponentProps> = ({ onFilterChange, filterParams, filterStyle }) => {
  const { t } = useTranslation();
  const { isMobile } = useViewInfo();
  const [show, setShow] = useState(false);
  const [selectedView, setSelectedView] = useState<string>('All views');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /** Code to hide Observer Error */
  useBeforeRender(() => {
    window.addEventListener('error', e => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) resizeObserverErr.className = 'hide-resize-observer';
        if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'hide-resize-observer';
      }
    });
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createParams = async (filterType: any, filterValue: any) => {
    if (!filterParams) {
      filterParams = {
        FILTER: {
          COL: [
            {
              name: 'fpaTypeCategory',
              type: 'any',
              value: 'com.atollon.service.fpatypecatogory.serviceactivity',
            },
          ],
        },
      };
    } else {
      //check if filterParams COL array don't have object with name fpaTypeCategory
      const fpaTypeCategoryExists = filterParams.FILTER.COL.some((col: any) => col.name === 'fpaTypeCategory');

      if (!fpaTypeCategoryExists) {
        filterParams.FILTER.COL.push({
          name: 'fpaTypeCategory',
          type: 'any',
          value: 'com.atollon.service.fpatypecatogory.serviceactivity',
        });
      }
    }

    const updateFilterParams = (name: string, type: string, value: string) => {
      let updatedArray = filterParams.FILTER.COL.map((obj: any) => (obj.name === name ? { ...obj, value } : obj));
      const hasNameSearch = filterParams.FILTER.COL.some((obj: any) => obj.name === name);

      if (!hasNameSearch) {
        updatedArray.push({ name, type, value });
      }
      filterParams.FILTER.COL = updatedArray;
    };

    const filterValueStr = filterType === 'view' ? filterValue : Array.from(filterValue).join(',');
    //console.log('filterValueStr', filterValueStr);
    switch (filterType) {
      case 'type':
        updateFilterParams('type', 'in', filterValueStr === 'All types' ? '' : filterValueStr);
        break;
      case 'view':
        updateFilterParams('state', 'in', filterValue === 'All views' ? '' : filterValue);
        break;
      case 'customState':
        updateFilterParams('customState', 'in', filterValueStr === 'All states' ? '' : filterValueStr);
        break;
      case 'user':
        updateFilterParams('responsibleUser', 'any', filterValueStr === 'All users' ? '' : filterValueStr);
        break;
      default:
        break;
    }
    //console.log('filterParams', filterParams);
    filterParams.FILTER.COL = filterParams.FILTER.COL.filter((col: any) => col.value !== '');
    onFilterChange(filterParams);
  };

  return isMobile ? (
    <>
      <View position={'relative'} width={'100%'} height={'64px'} UNSAFE_className={styles.mobile_header_parent}>
        <Flex direction={'column'} position={'absolute'} top={'calc(50% - 16px)'} left={'58px'} align-items={'start'} justifyContent={'start'}>
          <View position={'relative'} UNSAFE_className={styles.mobile_header_text_1}>
            {t('my_service_activities', { ns: 'layout_components' })}
          </View>
          <View position={'relative'} UNSAFE_className={styles.mobile_header_text_2}>
            {t('service_activities', { ns: 'layout_components' })}
          </View>
        </Flex>
        <View position={'absolute'} top={'calc(50% - 12px)'} left={'16px'} width={'24px'} height={'24px'} overflow={'hidden'}>
          <span onClick={handleShow} className="me-2">
            <i className="bi bi-list"></i>
          </span>
          <Offcanvas show={show} onHide={handleClose} placement="start">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Flex
                direction={'column'}
                position={'absolute'}
                top={'50px'}
                left={'15px'}
                width={'240px'}
                alignItems={'start'}
                justifyContent={'start'}
                gap={'5px'}
                UNSAFE_className={styles.views_menu_container}
              >
                <Flex direction={'row'} UNSAFE_className={styles.views_menu_heading} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
                  <View position={'relative'} UNSAFE_className={styles.menu_heading_text}>
                    {t('views', { ns: 'layout_components' })}
                  </View>
                </Flex>
                <Flex direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'4px'} UNSAFE_className={styles.filter_views_parent}>
                  <a
                    onClick={() => {
                      setSelectedView('All views');
                      createParams('view', 'All views');
                      handleClose();
                    }}
                  >
                    <Flex
                      direction={'row'}
                      height={'36px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      UNSAFE_className={selectedView === 'All views' ? styles.filter_views_container_selected : styles.filter_views_container}
                    >
                      <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                        <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                          {t('all_service_activities', { ns: 'layout_components' })}
                        </Flex>
                      </Flex>
                    </Flex>
                  </a>
                  <a
                    onClick={() => {
                      setSelectedView('1,2');
                      createParams('view', '1,2');
                      handleClose();
                    }}
                  >
                    <Flex alignSelf={'stretch'} UNSAFE_className={selectedView === '1,2' ? styles.filter_views_container_selected : styles.filter_views_container}>
                      <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'} alignSelf={'stretch'}>
                        <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                          {t('my_service_activities', { ns: 'layout_components' })}
                        </Flex>
                      </Flex>
                    </Flex>
                  </a>
                  <a
                    onClick={() => {
                      setSelectedView('3');
                      createParams('view', '3');
                      handleClose();
                    }}
                  >
                    <Flex alignSelf={'stretch'} UNSAFE_className={selectedView === '3' ? styles.filter_views_container_selected : styles.filter_views_container}>
                      <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'} alignSelf={'stretch'}>
                        <Flex position={'relative'} flex={1} alignSelf={'stretch'} UNSAFE_className={styles.filter_views_text}>
                          {t('today_service_activities', { ns: 'layout_components' })}
                        </Flex>
                      </Flex>
                    </Flex>
                  </a>
                </Flex>
              </Flex>
              <View position={'relative'} top={'85%'} width={'100%'} height={'1px'} UNSAFE_className={styles.separator}>
                <Flex direction={'column'} position={'absolute'} width={'240px'} alignItems={'start'} justifyContent={'start'} gap={'5px'} UNSAFE_className={styles.views_menu_container}>
                  <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.button_group_parent}>
                    <Flex position={'relative'} width={'266px'} height={'56px'} flexShrink={0} UNSAFE_className={styles.button_group_wrapper}>
                      <Flex direction={'row'} position={'absolute'} top={'calc(50% - 10px)'} left={'14px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.btn_style}>
                        <Link
                          isQuiet
                          onPress={() => {
                            console.log('Clicked on Add View');
                          }}
                        >
                          <>
                            <Plus fontSize={'25px'} />
                            {t('add_view', { ns: 'layout_components' })}
                          </>
                        </Link>
                      </Flex>
                    </Flex>
                    <Flex position={'relative'} width={'266px'} height={'56px'} flexShrink={0} UNSAFE_className={styles.button_group_wrapper}>
                      <Flex
                        direction={'row'}
                        position={'absolute'}
                        gap={'8px'}
                        top={'calc(50% - 10px)'}
                        left={'14px'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        UNSAFE_className={styles.btn_style}
                      >
                        <Link
                          isQuiet
                          onPress={() => {
                            console.log('Clicked on Edit View');
                          }}
                        >
                          <>
                            <Pen />
                            {t('edit_view', { ns: 'layout_components' })}
                          </>
                        </Link>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </View>
            </Offcanvas.Body>
          </Offcanvas>
        </View>

        <View position={'absolute'} top={'calc(50% - 12px)'} left={'calc(100% - 40px)'} width={'24px'} height={'24px'} overflow={'hidden'}>
          <div onClick={() => setIsOpen(true)}>
            <FilterIcon size={20} />
          </div>

          <DialogContainer
            isDismissable
            onDismiss={() => {
              setIsOpen(false);
            }}
          >
            {isOpen && (
              <Dialog size="L">
                <Content>
                  <View width={'100%'} position={'relative'} height={'100vh'} UNSAFE_className={styles.popup_bottom_sheet}>
                    <View
                      position={'absolute'}
                      top={'0px'}
                      left={'0px'}
                      width={'100%'}
                      height={'71px'}
                      overflow={'hidden'}
                      backgroundColor={'transparent'}
                      UNSAFE_className={styles.popup_header_parent}
                    >
                      <View position={'absolute'} top={'8px'} left={'calc(50% - 43px)'} width={'88px'} height={'2px'} UNSAFE_className={styles.popup_header_top_line}></View>
                      <View position={'absolute'} top={'32px'} left={'16px'} width={'328px'} height={'32px'} overflow={'hidden'}>
                        <View position={'relative'} UNSAFE_className={styles.popup_header_text}>
                          {t('filters', { ns: 'layout_components' })}
                        </View>
                        {/* <Flex
                          direction={'row'}
                          position={'absolute'}
                          top={'calc(50% - 7px)'}
                          right={'15px'}
                          height={'18px'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          UNSAFE_className={styles.popup_header_clearall}
                        >
                          <View position={'relative'} UNSAFE_className={styles.popup_header_clearall_text}>
                            Clear all
                          </View>
                        </Flex> */}
                      </View>
                    </View>
                    <Flex
                      direction={'column'}
                      position={'absolute'}
                      width={'95%'}
                      top={'70px'}
                      left={'16px'}
                      alignItems={'start'}
                      justifyContent={'start'}
                      gap={'24px'}
                      UNSAFE_className={styles.popup_header_body_parent}
                    >
                      <Flex direction={'column'} width={'100%'} alignItems={'start'} justifyContent={'start'} gap={'12px'} UNSAFE_className={styles.popup_header_body}>
                        {<ActivityStatusFilterComponent createParams={createParams} filterParams={filterParams} />}
                        {<UsersFilterComponent createParams={createParams} filterParams={filterParams} />}
                        {<ActivityTypeFilterComponent createParams={createParams} filterParams={filterParams} />}
                        {/* {<CategoryFilterComponent createParams={createParams} />} */}
                      </Flex>
                    </Flex>

                    {/* <View position={'absolute'} bottom={'0px'} left={'0px'} width={'100%'} height={'80px'} overflow={'hidden'} UNSAFE_className={styles.popup_header_footer_parent}>
                      <Flex
                        direction={'row'}
                        position={'absolute'}
                        top={'16px'}
                        left={'16px'}
                        width={'90%'}
                        height={'48px'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        UNSAFE_className={styles.popup_header_footer}
                      >
                        <View position={'relative'} UNSAFE_className={styles.popup_header_footer_btn}>
                          Choose
                        </View>
                      </Flex>
                    </View> */}
                  </View>
                </Content>
              </Dialog>
            )}
          </DialogContainer>
        </View>
      </View>
    </>
  ) : (
    <View width={'100%'} position={'relative'} backgroundColor={'static-white'} UNSAFE_className={styles.filter_menu_parent} UNSAFE_style={filterStyle}>
      <Flex
        direction={'column'}
        position={'absolute'}
        top={'24px'}
        left={'0px'}
        width={'240px'}
        alignItems={'start'}
        justifyContent={'start'}
        gap={'5px'}
        UNSAFE_className={styles.views_menu_container}
      >
        <Flex direction={'row'} UNSAFE_className={styles.views_menu_heading} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
          <View position={'relative'} UNSAFE_className={styles.menu_heading_text}>
            {t('views', { ns: 'layout_components' })}
          </View>
        </Flex>
        <Flex direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'4px'} UNSAFE_className={styles.filter_views_parent}>
          <a
            onClick={() => {
              setSelectedView('All views');
              createParams('view', 'All views');
            }}
          >
            <Flex
              direction={'row'}
              height={'36px'}
              alignItems={'center'}
              justifyContent={'center'}
              UNSAFE_className={selectedView === 'All views' ? styles.filter_views_container_selected : styles.filter_views_container}
            >
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('all_service_activities', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>
          <a
            onClick={() => {
              setSelectedView('1,2');
              createParams('view', '1,2');
            }}
          >
            <Flex UNSAFE_className={selectedView === '1,2' ? styles.filter_views_container_selected : styles.filter_views_container}>
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('my_service_activities', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>

          <a
            onClick={() => {
              setSelectedView('3');
              createParams('view', '3');
            }}
          >
            <Flex UNSAFE_className={selectedView === '3' ? styles.filter_views_container_selected : styles.filter_views_container}>
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('today_service_activities', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>
        </Flex>
      </Flex>
      <View position={'relative'} top={'185px'} width={'100%'} height={'1px'} UNSAFE_className={styles.separator}></View>
      <Flex
        direction={'column'}
        position={'absolute'}
        top={'209px'}
        left={'0px'}
        width={'240px'}
        alignItems={'start'}
        justifyContent={'start'}
        gap={'5px'}
        UNSAFE_className={styles.filters_menu_container}
      >
        <Flex direction={'row'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.filters_menu_wrapper}>
          <View position={'relative'} UNSAFE_className={styles.menu_heading_text}>
            {t('filters', { ns: 'layout_components' })}
          </View>
        </Flex>
        <ActivityStatusFilterComponent createParams={createParams} />
        <UsersFilterComponent createParams={createParams} />
        <ActivityTypeFilterComponent createParams={createParams} />
        {/* <CategoryFilterComponent createParams={createParams} /> */}
      </Flex>
    </View>
  );
};

export default FilterComponent;
