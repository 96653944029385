import { useCallback, useEffect, useRef, useState } from 'react';
import { useDependency } from '../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../hooks/UseViewInfo';
import { statusColor } from '../../../utils/helperUtil';
import useBrowserName from '../../../hooks/UseBrowserName';
import useScreenSize from '../../../hooks/UseScreenSize';
import moment from 'moment';
import { ToastQueue } from '@react-spectrum/toast';
import { useAsyncList } from 'react-stately';
import { Flex, View, ProgressCircle, Grid, TableView, TableBody, TableHeader, Row, Cell, Column, Heading, Content, ListBox, Item, IllustratedMessage, Link } from '@adobe/react-spectrum';
import { CircleFill } from 'react-bootstrap-icons';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import { FinderIcon } from '../../../components/protected/Icons/IconsLib';
import FilterComponent from './component/FilterComponent';
import HeaderComponent from './component/HeaderComponent';
import { invokeFinderApp } from '../../../utils/NavigationUtils';
import { ListActivityAdvancedRequest } from '../../../services/soap/project/requests/ListActivityAdvancedRequest';
import { ListStatusRequest, ListStatusType } from '../../../services/soap/project/requests/ListStatusRequest';
import { LayoutDefinition } from '../../../infra/protected/Layout/LayoutDefinitions';
import { GetFormPanelRequest } from '../../../services/soap/features/requests/GetFormPanelRequest';
import { SoapUtils } from '../../../utils/SoapUtils';
import { ListFormSettingsRequest } from '../../../services/soap/features/requests/ListFormSettingsRequest';
import { GetFormSettingsRequest } from '../../../services/soap/features/requests/GetFormSettingsRequest';
import CardComponent from './component/CardComponent';
//import { GetContextPathRequest } from '../../../services/soap/project/requests/GetContextPathRequest';
import { ComponentFactory } from '../../../components/protected/layout-components/ComponentFactory';
import FilterSearchTextBox from './component/FilterSearchTextBox';
import { FPAData, FPADataFactory } from '../../../infra/protected/FPA/FPAData';
import { usePreloadAssets } from '../../../hooks/UsePreloadAssets';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateAppInfo } from '../../../state/userWebLayout/userWebLayoutSlice';
import styles from './ServiceActivities.module.css';

interface Character {
  id: string;
  name: string;
}

export function ServiceActivities() {
  const { activityStatusMapping } = usePreloadAssets();
  const browserName = useBrowserName();
  const screenSize = useScreenSize();
  const { t } = useTranslation();
  const { isMobile } = useViewInfo();
  const { store, projectService, featureService } = useDependency();
  const dataStore = useSelector((state: any) => state.userWebLayout.appInfos);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [showLoader, setShowLoader] = useState(true);
  const [showLoaderDetail, setShowLoaderDetail] = useState(true);
  const [rowsData, setRowsData] = useState<any>([]);
  const [columnsData, setColumnsData] = useState<any>([]);
  const [pageView, setPageView] = useState<string>('Full');
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [activityStatusList, setActivityStatusList] = useState<any>([]);
  const [definition, setDefinition] = useState<LayoutDefinition | null>(null);
  const [showMobileDetailView, setShowMobileDetailView] = useState<boolean>(false);
  const [stopLoadData, setStopLoadData] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<FPAData | null>(null);
  const [statusBarColor, setStatusBarColor] = useState<string>('');
  const queryParams = useRef<any>({ FILTER: { COL: [] } });
  const totalActivityListRec = useRef<number>(0);
  const offSetLimit = useRef<number>(0);
  const dataRef = useRef<any>({});
  const PAGE_SIZE = 50;

  useEffect(() => {
    if (state.appKey && dataStore[state.appKey]) {
      if (dataStore[state.appKey].pageView) {
        dataRef.current.pageView = dataStore[state.appKey].pageView;
        setPageView(dataStore[state.appKey].pageView);
        return;
      }
    }
  }, [dataStore.appInfos, state.appKey]);

  useEffect(() => {
    (async () => {
      Promise.all([getActivityStatusArray(), getFormSettings()]);
    })();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        updateAppInfo({
          key: state.appKey,
          data: {
            pageView: dataRef.current.pageView,
          },
        })
      );
    };
  }, []);

  const getFormSettings = async () => {
    const resFormSettings = await featureService.listFormSettings(new ListFormSettingsRequest(store.Server, store.SessionId));

    if (resFormSettings?.result === 'OK' && resFormSettings?.count > 0) {
      //console.log('resFormSettings', resFormSettings);
      const advActivityformSetting = resFormSettings.SETTING.find(setting => setting.formId === 'ListActivityAdvanced' && setting.isDefault == 1);
      //console.log('advActivityformSetting', advActivityformSetting);
      const settingId = advActivityformSetting?.id;
      //console.log('settingId', settingId);
      if (settingId) {
        const response = await featureService.getFormSettings(new GetFormSettingsRequest(store.Server, store.SessionId, String(settingId)));
        if (response?.result === 'OK' && response?.count > 0) {
          //console.log('response', response);
          const filteredData = response.SETTING[0]?.ITEM.filter(item => String(item.visibility) === '1');
          const result = filteredData.map(item => {
            const key = item.itemname.includes('|') ? item.itemname.split('|')[1] : item.itemname;
            const name = item.caption && item.caption.trim() !== '' ? item.caption : item.name;
            return { key, name };
          });
          setColumnsData(result);
        }
      }
    }
  };

  const getActivityStatusArray = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await projectService.listActivityStatus2(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Activity, 'com.atollon.service.fpatypecatogory.serviceactivity'));

    if (res.result === 'OK' && res.STATUS.length > 0) {
      res?.STATUS.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name, color } = status;
        let colorString = statusColor(color);
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name, color: colorString });
      });
      //console.log('statusArray', statusArray);
      statusArray.unshift({ id: 'All states', name: 'All states', color: 'black', types: [] });
      setActivityStatusList(statusArray);
    }
  }, []);

  const getAcitivityListCount = async () => {
    //console.log('object', filterParams?.FILTER);
    let activityRes = await projectService.listActivityAdvanced(
      new ListActivityAdvancedRequest(store.Server, store.SessionId, {
        countOnly: '1',
        FILTER: queryParams.current?.FILTER || {
          COL: [
            {
              name: 'fpaTypeCategory',
              type: 'any',
              value: 'com.atollon.service.fpatypecatogory.serviceactivity',
            },
          ],
        },
      })
    );
    if (activityRes?.result == 'OK') {
      totalActivityListRec.current = activityRes.count != 0 ? activityRes.count : 0;
      return activityRes.count != 0 ? activityRes.count : 0;
    }
  };

  const loadServiceActivities = async () => {
    try {
      //console.log('object', filterParams?.FILTER);
      if (stopLoadData) {
        return rowsData;
      }
      setSearchedValue('');
      let activityRes = await projectService.listActivityAdvanced(
        new ListActivityAdvancedRequest(store.Server, store.SessionId, {
          limit: PAGE_SIZE,
          offset: offSetLimit.current ?? 0,
          FILTER: queryParams.current?.FILTER || {
            COL: [
              {
                name: 'fpaTypeCategory',
                type: 'any',
                value: 'com.atollon.service.fpatypecatogory.serviceactivity',
              },
            ],
          },
          ORDER: {
            COL: [
              {
                name: 'created',
                desc: 'true',
              },
            ],
          },
          FIELDS: {
            field: columnsData.map((column: any) => column.key),
          },
        })
      );
      //console.log('activityRes?.ACTIVITIES?.ACTIVITY)', activityRes);
      if (activityRes?.result == 'OK' && activityRes?.count > 0) {
        // add fullPath to each activity
        let listActivity: any = activityRes?.ACTIVITIES?.ACTIVITY;
        setRowsData(listActivity);
        //console.log('listActivity', listActivity);
        return listActivity;
      } else {
        setRowsData([]);
        ToastQueue.info(t('no_results', { ns: 'layout_components' }), { timeout: 50 });
        return [];
      }
    } finally {
      setShowLoader(false);
    }
  };

  const addStatusColor = (statusName: any) => {
    //console.log('status', statusName);
    const statusObj = activityStatusList.find((obj: any) => obj.name === statusName);
    return statusObj?.color;
  };

  let listActivityList = useAsyncList<Character>({
    async load({ cursor }) {
      //console.log('cursor', cursor);
      if (cursor && Number(cursor) * PAGE_SIZE >= totalActivityListRec.current) {
        setShowLoader(false);
        return { items: rowsData, cursor: undefined };
      }
      let totalRecords = await getAcitivityListCount();
      totalRecords = totalRecords ? Number(totalRecords) : 0;
      //console.log('totalRecords', totalRecords);
      let currentPage = cursor ? parseInt(cursor) : 0;
      //console.log('currentPage', currentPage);
      let offSet = PAGE_SIZE * currentPage;
      //console.log('offset', offSet);
      offSetLimit.current = offSet;
      let itemList = await loadServiceActivities();
      //console.log('itemList', itemList);
      return {
        items: itemList,
        cursor: String(offSet >= totalRecords ? undefined : currentPage + 1),
      };
    },
  });

  const reloadServiceActivities = (filterParams: any) => {
    //console.log('reloadServiceActivities Called', filterParams);
    setStopLoadData(false);
    queryParams.current = filterParams;
    totalActivityListRec.current = 0;
    offSetLimit.current = 0;
    listActivityList.reload();
  };

  const handleTableRowClick = (rowId: any): void => {
    invokeFinderApp(rowId);
  };

  const handleListRowClick = (rowId: any): void => {
    //const isSelectedRowNotEmpty = selectedRow && Object.keys(selectedRow).length > 0;
    //console.log('selectedKeys', selectedKeys?.has(rowId.currentKey));
    if (selectedKeys?.has(rowId.currentKey)) {
      setSelectedKeys(undefined);
      setDefinition(null);
    } else {
      setSelectedKeys(rowId);
      setPageView('Full');
      dataRef.current.pageView = 'Full';
      let selectedRow = rowsData.find((item: any) => item.id === rowId.currentKey);
      //console.log('selectedRow', selectedRow);
      getLayout(selectedRow);
    }
  };

  const handleRowClickMobile = (rowId: any): void => {
    //console.log('queryParams.current', queryParams.current);
    const nameSearchExists = queryParams.current.FILTER.COL.find((col: any) => col.name === 'nameSearch');
    //console.log('nameSearchExists', nameSearchExists);
    if (nameSearchExists) {
      setSearchedValue(nameSearchExists.value);
    }
    setSelectedKeys(undefined);
    let selectedRow = rowsData.find((item: any) => item.id === rowId.currentKey);
    //console.log('selectedRow', selectedRow);
    getLayout(selectedRow);
    setShowMobileDetailView(true);
  };

  function handleViewChange(view: string) {
    setPageView(view);
    dataRef.current.pageView = view;
  }

  const getStatusColor = (statusName: any) => {
    //console.log('status', statusName);
    const statusObj = activityStatusList.find((obj: any) => obj.name === statusName);
    return (
      <>
        <CircleFill style={{ paddingRight: '10px' }} size={'18px'} color={statusObj?.color} /> {statusName}
      </>
    );
  };

  const getLayout = async (selectedRow: any) => {
    try {
      //console.log('selectedRow', selectedRow);
      let stausBarColor = addStatusColor(selectedRow.customStateName);
      setStatusBarColor(stausBarColor);
      let resSelectedItem = FPADataFactory.createFromActivityAdvanced(selectedRow, activityStatusMapping);
      setSelectedItem(resSelectedItem);
      let typeId = selectedRow.type;
      const layout = await featureService.getFormPanel(new GetFormPanelRequest(store.Server, store.SessionId, '' + typeId));

      if (layout.count > 0) {
        let scheme = SoapUtils.parseXmlString2(layout.ROW.SCHEME);
        let layoutObject = JSON.parse(scheme.ActivityPanelSettings.layoutObject);
        setDefinition(layoutObject);
      } else {
        setDefinition(null);
      }
    } catch (err) {
      console.error('getlayout error: ', err);
    } finally {
      setShowLoaderDetail(false);
    }
  };

  const Loader = () => (
    <Flex width="100%" justifyContent="center" marginTop={10}>
      <ProgressCircle aria-label="Loading…" isIndeterminate />
    </Flex>
  );

  const NoResultsMessage = () => (
    <Flex direction="row" width="100%" alignContent="center" justifyContent="center">
      <IllustratedMessage>
        <NotFound />
        <Heading>{t('no_results', { ns: 'layout_components' })}</Heading>
        <Content>{t('try_another_search', { ns: 'layout_components' })}</Content>
      </IllustratedMessage>
    </Flex>
  );

  const getHeight = (baseHeight: number) => {
    if (browserName === 'Chrome') {
      return `${baseHeight - 120}px`;
    } else if (browserName === 'Safari') {
      return `${baseHeight - 120}px`;
    } else {
      return `${baseHeight - 120}px`;
    }
  };

  const detailStyle = {
    height: getHeight(screenSize.height - 20),
  };

  const cardStyle = {
    height: getHeight(screenSize.height),
  };

  const filterStyle = {
    height: getHeight(screenSize.height + 60),
    overflow: 'hidden auto',
  };

  return (
    <>
      {isMobile ? (
        <>
          {!showMobileDetailView && (
            <>
              <FilterComponent onFilterChange={reloadServiceActivities} filterParams={queryParams.current} />
              <View position={'relative'} width={'100%'} height={'100%'} UNSAFE_className={styles.card_list_parent}>
                <Flex direction={'column'} position={'absolute'} width={'100%'} top={'72px'} left={'0%'} alignItems={'start'} justifyContent={'start'} gap={'12px'}>
                  {showLoader ? (
                    <Loader />
                  ) : (
                    <ListBox
                      id="listActivityCardsMobile"
                      selectionMode="single"
                      onSelectionChange={handleRowClickMobile}
                      marginStart={'18px'}
                      maxHeight={'100vh'}
                      minHeight={'130px'}
                      defaultSelectedKeys={selectedKeys}
                      aria-label="Service Activities Cards Mobile"
                      items={listActivityList.items}
                      isLoading={listActivityList.isLoading}
                      width={'90%'}
                    >
                      {item => (
                        <Item key={item.id} textValue={item.name}>
                          <CardComponent item={item} activityStatusList={activityStatusList} />
                        </Item>
                      )}
                    </ListBox>
                  )}
                </Flex>
                <Flex
                  direction={'column'}
                  position={'absolute'}
                  width={'calc(100% - 32px)'}
                  top={'16px'}
                  right={'16px'}
                  left={'16px'}
                  alignItems={'start'}
                  justifyContent={'start'}
                  UNSAFE_className={styles.search_parent}
                >
                  <Flex direction={'row'} alignSelf={'stretch'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'end'} gap={'8px'} UNSAFE_className={styles.search_field}>
                    <FilterSearchTextBox onSearchChange={reloadServiceActivities} filterParams={queryParams.current} serachedText={searchedValue} />
                    <i className={`${styles.icon_search} bi bi-search`}></i>
                  </Flex>
                </Flex>
                <Flex
                  direction={'row'}
                  position={'fixed'}
                  right={'16px'}
                  bottom={'85px'}
                  height={'48px'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={'8px'}
                  UNSAFE_className={styles.add_new_parent}
                >
                  <Link
                    isQuiet
                    onPress={e => {
                      ToastQueue.info('Under Development', { timeout: 10 });
                    }}
                    UNSAFE_className={styles.add_new_btn}
                  >
                    <i className="bi bi-plus fs-5">
                      <View UNSAFE_className={styles.add_new_btn_text}>{t('add_new', { ns: 'layout_components' })}</View>
                    </i>
                  </Link>
                </Flex>
              </View>
            </>
          )}
          {showMobileDetailView && (
            <>
              <View position={'relative'} width={'100%'} height={'64px'} overflow={'hidden'} UNSAFE_className={styles.layout_header_parent}>
                <View position={'absolute'} top={'calc(50% - 14px)'} left={'15%'} UNSAFE_className={styles.layout_header_text}>
                  {selectedItem?.item_type_name}
                </View>
                <View position={'absolute'} top={'calc(50% - 15px)'} left={'25px'} width={'24px'} height={'30px'} overflow={'hidden'} UNSAFE_className={styles.layout_header_btn_back}>
                  <Link
                    onPress={() => {
                      setStopLoadData(true);
                      setShowMobileDetailView(false);
                    }}
                  >
                    <i className="bi bi-chevron-left fs-5" />
                  </Link>
                </View>
                <a
                  onClick={() => {
                    setStopLoadData(true);
                    setShowMobileDetailView(false);
                  }}
                  className={styles.layout_header_btn_folder}
                >
                  <FinderIcon size={24} />
                </a>
              </View>
              <Flex direction="column" alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.detailsHolder} UNSAFE_style={{ padding: '20px', paddingTop: '0px' }}>
                {selectedItem && (
                  <>
                    <View UNSAFE_className={styles.service_status_bar} marginBottom={'5px'} marginTop={'0px'} UNSAFE_style={{ backgroundColor: statusBarColor }}></View>
                    <ComponentFactory
                      selectedItem={selectedItem}
                      definition={definition}
                      systemAttributes={{
                        onPrint: item => console.log('onPrint', item),
                        onEdit: item => console.log('onEdit', item),
                        onDelete: item => console.log('onDelete', item),
                        onChat: item => console.log('onChat', item),
                        onConfig: (item, type) => console.log('onConfig', item, type),
                      }}
                    />
                  </>
                )}
              </Flex>
            </>
          )}
        </>
      ) : (
        <Grid
          areas={pageView == 'Full' ? ['filter header header', 'filter card detail'] : ['filter header header', 'filter detail detail']}
          columns={pageView == 'Full' ? ['17%', '28%', '55%'] : ['17%', '28%', '55%']}
          rows={pageView == 'Full' ? ['auto', '1fr'] : ['auto', '1fr']}
          height="100%"
          width="100%"
          UNSAFE_style={{ backgroundColor: '#f5f6f9', padding: '0px', margin: '0px', overflow: 'hidden' }}
        >
          <View gridArea="filter">
            <FilterComponent onFilterChange={reloadServiceActivities} filterParams={queryParams.current} filterStyle={filterStyle} />
          </View>
          <View gridArea="header" UNSAFE_style={pageView == 'Full' ? {} : { height: '100%' }}>
            <HeaderComponent onPageViewChange={handleViewChange} autoChanged={pageView} filterParams={queryParams.current} onSearchChange={reloadServiceActivities} />
          </View>
          {pageView == 'Full' ? (
            <>
              {showLoader ? (
                <Loader />
              ) : (
                <>
                  <View gridArea="card" UNSAFE_style={{ backgroundColor: '#f5f6f9' }}>
                    {rowsData?.length > 0 ? (
                      <ListBox
                        id="listActivityCards"
                        selectionMode="single"
                        onSelectionChange={handleListRowClick}
                        marginStart={'13px'}
                        minHeight={'130px'}
                        defaultSelectedKeys={selectedKeys}
                        aria-label="Service Activities Cards"
                        items={listActivityList.items}
                        isLoading={listActivityList.isLoading}
                        width={'94%'}
                        UNSAFE_style={cardStyle}
                        marginTop={'13px'}
                      >
                        {item => (
                          <Item key={item.id} textValue={item.name}>
                            <CardComponent item={item} activityStatusList={activityStatusList} />
                          </Item>
                        )}
                      </ListBox>
                    ) : (
                      <NoResultsMessage />
                    )}
                  </View>
                  <View gridArea="detail" backgroundColor={'static-white'} borderRadius={'large'} padding={'size-100'} paddingTop={'0px'} marginTop={'17px'} UNSAFE_style={detailStyle} width={'98%'}>
                    {definition &&
                      (showLoaderDetail ? (
                        <Loader />
                      ) : (
                        <Flex
                          direction="column"
                          gap="size-100"
                          height="100%"
                          alignItems={'center'}
                          top={0}
                          justifyContent={'center'}
                          UNSAFE_className={styles.detailsHolder}
                          UNSAFE_style={{ overflow: 'hidden auto' }}
                          marginStart={'10px'}
                        >
                          {selectedItem && (
                            <>
                              <View UNSAFE_className={styles.service_status_bar} UNSAFE_style={{ backgroundColor: statusBarColor }}></View>
                              <View height={'2px'}>&nbsp;</View>
                              <ComponentFactory
                                selectedItem={selectedItem}
                                definition={definition}
                                systemAttributes={{
                                  onPrint: item => console.log('onPrint', item),
                                  onEdit: item => console.log('onEdit', item),
                                  onDelete: item => console.log('onDelete', item),
                                  onChat: item => console.log('onChat', item),
                                  onConfig: (item, type) => console.log('onConfig', item, type),
                                }}
                              />
                            </>
                          )}
                        </Flex>
                      ))}
                  </View>
                </>
              )}
            </>
          ) : (
            <View gridArea="detail">
              {showLoader ? (
                <Loader />
              ) : (
                <View position={'relative'} overflow={'hidden'} width={'100%'} height={'660px'} UNSAFE_className={styles.service_activities}>
                  {columnsData?.length > 0 && rowsData?.length > 0 ? (
                    <TableView
                      aria-label="Service Activities"
                      // onSelectionChange={handleTableRowClick}
                      selectionMode="single"
                      defaultSelectedKeys={selectedKeys}
                      selectionStyle="highlight"
                      width={{ base: '100%', L: '100%', M: '100%' }}
                      minHeight={'100px'}
                      maxHeight={{ base: '800px', L: '100vh' }}
                      onAction={handleTableRowClick}
                    >
                      <TableHeader columns={columnsData}>
                        {(column: any) => (
                          <Column showDivider key={column.key}>
                            {column.name}
                          </Column>
                        )}
                      </TableHeader>
                      <TableBody items={listActivityList.items} loadingState={listActivityList.loadingState}>
                        {(item: any) => (
                          <Row>
                            {columnKey => (
                              <Cell>
                                {(() => {
                                  if (moment(item[columnKey], moment.ISO_8601, true).isValid()) {
                                    return moment(item[columnKey]).format('MM/DD/YYYY | hh:mm A');
                                  } else if (columnKey === 'customStateName') {
                                    return getStatusColor(item[columnKey]);
                                  } else {
                                    return item[columnKey];
                                  }
                                })()}
                              </Cell>
                            )}
                          </Row>
                        )}
                      </TableBody>
                    </TableView>
                  ) : (
                    <NoResultsMessage />
                  )}
                </View>
              )}
            </View>
          )}
        </Grid>
      )}
    </>
  );
}
