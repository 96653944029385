export const truncateText = (text: string, maxLength: number) => {
  return text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

export function statusColor(color: string) {
  if (!color || color === '0') return '';
  try {
    let finalColor = (+color).toString(16).split('').reverse();
    let padding = Array(6 - finalColor?.length).fill('0');
    let finalColorString = '#' + [...finalColor, ...padding].join('');
    return finalColorString === '#ffffff' ? '#FAFAFA' : finalColorString;
  } catch (error) {
    return '';
  }
}
