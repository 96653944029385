import React, { useCallback, useEffect, useState } from 'react';
import { Flex, ListBox, Item, View, ProgressCircle, Link } from '@adobe/react-spectrum';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { truncateText } from '../../../../../utils/helperUtil';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { ListActivityTypeRequest } from '../../../../../services/soap/project/requests/ListActivityTypeRequest';
import { useTranslation } from 'react-i18next';
import styles from './activity_type_filter_component.module.css';

interface ActivityTypeFilterComponentProps {
  createParams: (filterType: any, filterValue: any) => void;
  filterParams?: any;
}

const ActivityTypeFilterComponent: React.FC<ActivityTypeFilterComponentProps> = ({ createParams, filterParams }) => {
  const { t } = useTranslation();
  const { store, projectService } = useDependency();
  const [toggleListVisibility, setToggleListVisibility] = useState<any>({ typeList: true });
  const [typeSelectedKey, setTypeSelectedKey] = useState<Set<string>>(new Set(['All types']));
  const [activityType, setActivityType] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const typeExists = filterParams?.FILTER.COL.find((col: any) => col.name === 'type');
      if (typeExists) {
        setTypeSelectedKey(typeExists.value.split(','));
      }
      const filterActivityType = JSON.parse(localStorage.getItem('filterActivityType') || '[]');
      //console.log('filterActivityType', filterActivityType);
      if (filterActivityType.length > 0) {
        setActivityType(filterActivityType);
        setShowLoader(false);
      } else {
        await getActivityType();
      }
    })();
  }, []);

  const getActivityType = useCallback(async () => {
    try {
      let res = await projectService.listActivityType(new ListActivityTypeRequest(store.Server, store.SessionId, { fpaTypeCategory: 'com.atollon.service.fpatypecatogory.serviceactivity' }));
      if (res.result === 'OK') {
        let typeArray: any = [];
        res?.TYPE.forEach((type: any) => {
          const { id, name } = type;
          typeArray.push({ id, name });
        });
        //typeArray.unshift({ id: 'All types', name: 'All types' });
        localStorage.setItem('filterActivityType', JSON.stringify(typeArray));
        setActivityType(typeArray);
      }
    } finally {
      setShowLoader(false);
    }
  }, []);

  const handleSelectionChange = (value: any) => {
    const arrValue = Array.from(value);
    const allTypesCheck = arrValue.includes('All types') && arrValue.indexOf('All types') !== 0;

    if (allTypesCheck) {
      const allTypesSet = new Set(['All types']);
      setTypeSelectedKey(allTypesSet);
      createParams('type', allTypesSet);
    } else {
      const filteredValue = arrValue.filter(item => item !== 'All types');
      const updateValue = filteredValue.length > 0 ? new Set(filteredValue) : new Set(['All types']);
      setTypeSelectedKey(new Set(Array.from(updateValue).map(String)));
      createParams('type', updateValue);
    }
  };

  if (showLoader) {
    return (
      <Flex width="100%" height="100%" UNSAFE_style={{ backgroundColor: 'white' }} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} minWidth={'200px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'}>
        <Flex direction={'column'} gap={'4px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.activity_type_container}>
          <Flex direction={'row'} height={'36px'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'space-between'} UNSAFE_className={styles.activity_type_heading}>
            <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
              <View position={'relative'} UNSAFE_className={styles.activity_type_heading_text}>
                {t('activity_type', { ns: 'layout_components' })}
              </View>
            </Flex>
            {toggleListVisibility.typeList ? (
              <ChevronDown color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, typeList: false }))} style={{ cursor: 'pointer' }} />
            ) : (
              <ChevronUp color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, typeList: true }))} style={{ cursor: 'pointer' }} />
            )}
          </Flex>
          <Flex
            direction={'column'}
            alignSelf={'stretch'}
            alignItems={'start'}
            justifyContent={'start'}
            UNSAFE_className={styles.activity_type_component}
            UNSAFE_style={{ display: toggleListVisibility.typeList ? '' : 'none' }}
          >
            <ListBox
              width="100%"
              selectionMode="multiple"
              defaultSelectedKeys={typeSelectedKey}
              selectedKeys={typeSelectedKey}
              items={activityType}
              onSelectionChange={value => {
                handleSelectionChange(value);
              }}
              aria-label='none'
            >
              {(item: any) => <Item key={item.id}>{truncateText(item.name, 35)}</Item>}
            </ListBox>
            <View position={'relative'} width={'100%'} height={'20px'} UNSAFE_className={styles.activity_type_clear_all_container}>
              <View position={'relative'} UNSAFE_className={styles.activity_type_clear_all_text}>
                <Link onPress={() => handleSelectionChange(new Set(['All types']))}>{t('clear_all', { ns: 'layout_components' })}</Link>
              </View>
            </View>
          </Flex>
        </Flex>
      </Flex>
    );
  }
};

export default ActivityTypeFilterComponent;
