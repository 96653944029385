import { useEffect, useState } from 'react';
import { TextField } from '@adobe/react-spectrum';
import { useDebounce } from '@uidotdev/usehooks';

interface IFilterSearchTextBoxProps {
  onSearchChange: (params: {}) => void;
  filterParams: any;
  serachedText?: string;
}

const FilterSearchTextBox: React.FC<IFilterSearchTextBoxProps> = ({ onSearchChange, filterParams, serachedText }) => {
  const [filterTerm, setFilterTerm] = useState<string>(serachedText || '');
  const debouncedFilterTerm = useDebounce(filterTerm, 1000);

  useEffect(() => {
    (async () => {
      //console.log('FilterSearchTextBox filterParams', filterParams);
      searchServiceActivities();
    })();
  }, [debouncedFilterTerm]);

  const handleFiltering = async (e: any) => {
    setFilterTerm(e);
  };

  const searchServiceActivities = async () => {
    //console.log('debouncedFilterTerm', debouncedFilterTerm);
    //console.log('filterParams', filterParams);
    if (!filterParams) {
      filterParams = {
        FILTER: {
          COL: [
            {
              name: 'fpaTypeCategory',
              type: 'any',
              value: 'com.atollon.service.fpatypecatogory.serviceactivity',
            },
          ],
        },
      };
    } else {
      const fpaTypeCategoryExists = filterParams.FILTER.COL.some((col: any) => col.name === 'fpaTypeCategory');
      if (!fpaTypeCategoryExists) {
        filterParams.FILTER.COL.push({
          name: 'fpaTypeCategory',
          type: 'any',
          value: 'com.atollon.service.fpatypecatogory.serviceactivity',
        });
      }
    }

    const updatedArray = filterParams.FILTER.COL.map((obj: any) => (obj.name === 'nameSearch' ? { ...obj, value: debouncedFilterTerm } : obj));

    const hasNameSearch = filterParams.FILTER.COL.some((obj: any) => obj.name === 'nameSearch');

    if (!hasNameSearch && debouncedFilterTerm) {
      updatedArray.push({
        name: 'nameSearch',
        type: 'contains',
        value: debouncedFilterTerm,
      });
    }

    filterParams.FILTER.COL = updatedArray;
    filterParams.FILTER.COL = filterParams.FILTER.COL.filter((col: any) => col.value !== '');
    onSearchChange(filterParams);
  };

  return <TextField value={filterTerm} width={'100%'} onChange={handleFiltering} aria-label='none' />;
};

export default FilterSearchTextBox;
