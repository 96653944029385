import { useState, useEffect } from 'react';
import { ToastQueue } from '@react-spectrum/toast';
import { Flex, View, ProgressCircle, Link, ToggleButton } from '@adobe/react-spectrum';
import { TableIcon, CardIcon } from '../../../../components/protected/Icons/IconsLib';
import { Grid, Fullscreen } from 'react-bootstrap-icons';
import FilterSearchTextBox from './FilterSearchTextBox';
import { useTranslation } from 'react-i18next';
import styles from './header_component.module.css';

interface IHeaderComponentProps {
  onPageViewChange: (view: string) => void;
  autoChanged: string;
  filterParams: any;
  onSearchChange: (params: {}) => void;
}

const HeaderComponent: React.FC<IHeaderComponentProps> = ({ onPageViewChange, autoChanged, filterParams, onSearchChange }) => {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  const [selectedButton, setSelectedButton] = useState<string>('Grid');

  useEffect(() => {
    setSelectedButton(prev => (prev === autoChanged ? prev : autoChanged));
  }, [autoChanged]);

  const handleToggle = (view: string) => {
    setSelectedButton(prev => (prev === view ? prev : view));
    onPageViewChange(view);
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <View position={'relative'} width={'100%'} height={'56px'} overflow={'hidden'} UNSAFE_className={styles.header_parent}>
        <ToggleButton isSelected={selectedButton === 'Grid'} onChange={() => handleToggle('Grid')} isEmphasized UNSAFE_className={styles.grid_view_icon}>
          {/* <TableIcon size={20} /> */} <Grid />
        </ToggleButton>
        <ToggleButton isSelected={selectedButton === 'Full'} onChange={() => handleToggle('Full')} isEmphasized UNSAFE_className={styles.full_view_icon}>
          {/* <CardIcon size={20} /> */} <Fullscreen />
        </ToggleButton>
        <Flex direction={'row'} position={'absolute'} top={'0px'} left={'20px'} alignItems={'center'} justifyContent={'start'} gap={'16px'}>
          <Link
            isQuiet
            onPress={e => {
              ToastQueue.info('Under Development', { timeout: 10 });
            }}
            UNSAFE_className={styles.add_new_btn}
          >
            <i className="bi bi-plus fs-5">
              <View UNSAFE_className={styles.add_new_btn_text}>{t('add_new', { ns: 'layout_components' })}</View>
            </i>
          </Link>

          <Flex direction={'column'} width={'205px'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.activity_search_input}>
            <Flex direction={'row'} height={'32px'} alignSelf={'stretch'} flexShrink={0} alignItems={'center'} justifyContent={'end'} UNSAFE_className={styles.activity_search}>
              {/* <TextField width={'100%'} onChange={handleFiltering} /> */}
              <FilterSearchTextBox onSearchChange={onSearchChange} filterParams={filterParams} />
              <i className={`${styles.icon_activity_search} bi bi-search`}></i>
            </Flex>
          </Flex>
          <View position={'relative'} width={'1px'} height={'56px'} UNSAFE_className={styles.frame_divider}></View>
        </Flex>
      </View>
    );
  }
};

export default HeaderComponent;
