import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Item, View, ProgressCircle, ListBox, Link } from '@adobe/react-spectrum';
import { truncateText } from '../../../../../utils/helperUtil';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { useTranslation } from 'react-i18next';
import styles from './project_type_filter_component.module.css';
import { ListProjectTemplateRequest } from '../../../../../services/soap/project/requests/ListProjectTemplateRequest';

interface ProjectTypeFilterComponentProps {
  createParams: (filterType: any, filterValue: any) => void;
  filterParams?: any;
  fpaTypeCategory?: string;
}

const ProjectTypeFilterComponent: React.FC<ProjectTypeFilterComponentProps> = ({ createParams, filterParams, fpaTypeCategory }) => {
  const { t } = useTranslation();
  const { store, projectService } = useDependency();
  const [toggleListVisibility, setToggleListVisibility] = useState<any>({ projectTypeList: true });
  const [projectTypeSelectedKey, setProjectTypeSelectedKey] = useState<Set<string>>(new Set(['All types']));
  const [projectTypeList, setProjectTypeList] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const typeExists = filterParams?.FILTER.COL.find((col: any) => col.name === 'type');
      if (typeExists) {
        setProjectTypeSelectedKey(typeExists.value.split(','));
      }
      const filterprojectTypeList = JSON.parse(localStorage.getItem('filterProjectTypeList') ?? '[]');
      //console.log('filterprojectTypeList', filterprojectTypeList);
      if (filterprojectTypeList.length > 0) {
        setProjectTypeList(filterprojectTypeList);
        setShowLoader(false);
      } else {
        await getProjectType();
      }
    })();
  }, []);

  const getProjectType = useCallback(async () => {
    try {
      let resProjectType: any = await projectService.listProjectTemplate(new ListProjectTemplateRequest(store.Server, store.SessionId, { fpaTypeCategory }));
      if (resProjectType?.result === 'OK' && resProjectType?.count == 1) {
        //console.log('resProjectType', resProjectType);
        const projectTypeArray = resProjectType?.PROJECTTEMPLATE.map((template: any) => {
          const { id, name } = template;
          return { id, name };
        });
        //projectTypeArray.unshift({ id: 'All types', name: 'All types' });
        localStorage.setItem('filterProjectTypeList', JSON.stringify(projectTypeArray));
        setProjectTypeList(projectTypeArray);
      } else if (resProjectType?.result === 'OK' && resProjectType?.count > 1) {
        let projectTypeArray: any = [];
        resProjectType?.TYPE_LIST?.forEach((record: any) => {
          const { id, name } = record.TYPE;
          projectTypeArray.push({ id, name });
        });
        //projectTypeArray.unshift({ id: 'All types', name: 'All types' });
        localStorage.setItem('filterProjectTypeList', JSON.stringify(projectTypeArray));
        setProjectTypeList(projectTypeArray);
      }
    } finally {
      setShowLoader(false);
    }
  }, []);

  const handleSelectionChange = (value: any) => {
    const arrValue = Array.from(value);
    const allProjectTypeCheck = arrValue.includes('All types') && arrValue.indexOf('All types') !== 0;

    if (allProjectTypeCheck) {
      const allProjectTypeSet = new Set(['All types']);
      setProjectTypeSelectedKey(allProjectTypeSet);
      createParams('type', allProjectTypeSet);
    } else {
      const filteredValue = arrValue.filter(item => item !== 'All types');
      const updateValue = filteredValue.length > 0 ? new Set(filteredValue) : new Set(['All types']);
      setProjectTypeSelectedKey(new Set(Array.from(updateValue).map(String)));
      createParams('type', updateValue);
    }
  };

  if (showLoader) {
    return (
      <Flex width="100%" height="100%" UNSAFE_style={{ backgroundColor: 'white' }} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} minWidth={'200px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_style={{ display: '' }}>
        <Flex direction={'column'} gap={'4px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.project_type_container}>
          <Flex direction={'row'} height={'36px'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'space-between'} UNSAFE_className={styles.project_type_heading}>
            <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
              <View position={'relative'} UNSAFE_className={styles.project_type_heading_text}>
                {t('project_type', { ns: 'layout_components' })}
              </View>
            </Flex>
            {toggleListVisibility.projectTypeList ? (
              <ChevronDown color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, projectTypeList: false }))} style={{ cursor: 'pointer' }} />
            ) : (
              <ChevronUp onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, projectTypeList: true }))} style={{ cursor: 'pointer' }} />
            )}
          </Flex>
          <Flex
            direction={'column'}
            alignSelf={'stretch'}
            alignItems={'start'}
            justifyContent={'start'}
            UNSAFE_className={styles.project_type_component}
            UNSAFE_style={{ display: toggleListVisibility.projectTypeList ? '' : 'none' }}
          >
            <ListBox
              width="100%"
              selectionMode="multiple"
              defaultSelectedKeys={projectTypeSelectedKey}
              selectedKeys={projectTypeSelectedKey}
              items={projectTypeList}
              onSelectionChange={value => {
                handleSelectionChange(value);
              }}
              aria-label="none"
            >
              {(item: any) => <Item key={item.id}>{truncateText(item.name, 35)}</Item>}
            </ListBox>
            <View position={'relative'} width={'100%'} height={'20px'} UNSAFE_className={styles.project_type_clear_all_container}>
              <View position={'relative'} UNSAFE_className={styles.project_type_clear_all_text}>
                <Link
                  onPress={() => {
                    handleSelectionChange(new Set(['All types']));
                  }}
                >
                  {t('clear_all', { ns: 'layout_components' })}
                </Link>
              </View>
            </View>
          </Flex>
        </Flex>
      </Flex>
    );
  }
};

export default ProjectTypeFilterComponent;
