import React, { useCallback, useEffect, useState } from 'react';
import { Flex, ListBox, Item, View, ProgressCircle, Link } from '@adobe/react-spectrum';
import { CircleFill, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { statusColor, truncateText } from '../../../../../utils/helperUtil';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { ListStatusRequest, ListStatusType } from '../../../../../services/soap/project/requests/ListStatusRequest';
import { useTranslation } from 'react-i18next';
import styles from './activity_status_filter_component.module.css';
interface ActivityStatusFilterComponentProps {
  createParams: (filterType: any, filterValue: any) => void;
  filterParams?: any;
}

const ActivityStatusFilterComponent: React.FC<ActivityStatusFilterComponentProps> = ({ createParams, filterParams }) => {
  const { t } = useTranslation();
  const { store, projectService } = useDependency();
  const [toggleListVisibility, setToggleListVisibility] = useState<any>({ stateList: true });
  const [stateSelectedKey, setStateSelectedKey] = useState<Set<string>>(new Set(['All states']));
  const [activityStatusList, setActivityStatusList] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const customStateExists = filterParams?.FILTER.COL.find((col: any) => col.name === 'customState');
      if (customStateExists) {
        setStateSelectedKey(customStateExists.value.split(','));
      }
      const filterActivityStatusList = JSON.parse(localStorage.getItem('filterActivityStatusList') || '[]');
      //console.log('filterActivityStatusList', filterActivityStatusList);
      if (filterActivityStatusList.length > 0) {
        setActivityStatusList(filterActivityStatusList);
        setShowLoader(false);
      } else {
        await getActivityStatusArray();
      }
    })();
  }, []);

  const getActivityStatusArray = useCallback(async () => {
    try {
      const statusArray: any = [];
      let res: any = await projectService.listActivityStatus2(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Activity, 'com.atollon.service.fpatypecatogory.serviceactivity'));

      if (res.result === 'OK' && res.STATUS.length > 0) {
        res?.STATUS.forEach((status: any) => {
          // Extract id and name from each STATUS object
          const { id, name, color } = status;
          let colorString = statusColor(color);
          // Push an object containing id and name to the statusArray
          statusArray.push({ id, name, color: colorString });
        });
        //console.log('statusArray', statusArray);
        //statusArray.unshift({ id: 'All states', name: 'All states', color: 'black', types: [] });
        localStorage.setItem('filterActivityStatusList', JSON.stringify(statusArray));
        setActivityStatusList(statusArray);
      }
    } finally {
      setShowLoader(false);
    }
  }, []);

  const handleSelectionChange = (value: any) => {
    //console.log('value', value);
    const arrValue = Array.from(value);
    const allStatesCheck = arrValue.includes('All states') && arrValue.indexOf('All states') !== 0;

    if (allStatesCheck) {
      const allStatesSet = new Set(['All states']);
      setStateSelectedKey(allStatesSet);
      createParams('customState', allStatesSet);
    } else {
      const filteredValue = arrValue.filter(item => item !== 'All states');
      const updateValue = filteredValue.length > 0 ? new Set(filteredValue) : new Set(['All states']);
      setStateSelectedKey(new Set(Array.from(updateValue).map(String)));
      createParams('customState', updateValue);
    }
  };
  if (showLoader) {
    return (
      <Flex width="100%" height="100%" UNSAFE_style={{ backgroundColor: 'white' }} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} minWidth={'200px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'}>
        <Flex direction={'column'} gap={'4px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.activity_status_container}>
          <Flex direction={'row'} height={'36px'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'space-between'} UNSAFE_className={styles.activity_status_heading}>
            <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
              <View position={'relative'} UNSAFE_className={styles.activity_status_heading_text}>
                {t('activity_status', { ns: 'layout_components' })}
              </View>
            </Flex>

            {toggleListVisibility.stateList ? (
              <ChevronDown color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, stateList: false }))} style={{ cursor: 'pointer' }} />
            ) : (
              <ChevronUp color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, stateList: true }))} style={{ cursor: 'pointer' }} />
            )}
          </Flex>
          <Flex
            direction={'column'}
            alignSelf={'stretch'}
            alignItems={'start'}
            justifyContent={'start'}
            UNSAFE_className={styles.activity_status_component}
            UNSAFE_style={{ display: toggleListVisibility.stateList ? '' : 'none' }}
          >
            <ListBox
              id="listActivityStatus"
              width="100%"
              selectionMode="multiple"
              defaultSelectedKeys={stateSelectedKey}
              selectedKeys={stateSelectedKey}
              items={activityStatusList}
              onSelectionChange={(value: any) => {
                handleSelectionChange(value);
              }}
              UNSAFE_style={{ color: '#455a64 !important' }}
              aria-label='none'
            >
              {(item: any) => (
                <Item key={item.id} textValue={truncateText(item.name, 18)}>
                  <Flex direction={'row'} height={'30px'} UNSAFE_style={{ paddingBottom: '30px' }}>
                    <View>
                      <CircleFill style={{ paddingRight: '10px', paddingTop: '6px' }} size={'18px'} color={item.color} />
                    </View>
                    <View UNSAFE_style={{ whiteSpace: 'nowrap', color: '#455a64 !important', lineHeight: '30px' }}>
                      <span title={item.name}>{truncateText(item.name, 18)}</span>
                    </View>
                  </Flex>
                </Item>
              )}
            </ListBox>
            <View position={'relative'} width={'100%'} height={'20px'} UNSAFE_className={styles.activity_status_clear_all_container}>
              <View position={'relative'} UNSAFE_className={styles.activity_status_clear_all_text}>
                <Link onPress={() => handleSelectionChange(new Set(['All states']))}>{t('clear_all', { ns: 'layout_components' })}</Link>
              </View>
            </View>
          </Flex>
        </Flex>
      </Flex>
    );
  }
};

export default ActivityStatusFilterComponent;
