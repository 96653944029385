import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListProjectTemplateRequest extends IBaseRequest {
    iType?:           string;
    treeHandle?:      string;
    fpaTypeCategory?: string;
}

export class ListProjectTemplateRequest extends BaseRequest implements IListProjectTemplateRequest {
    public iType?:           string;
    public treeHandle?:      string;
    public fpaTypeCategory?: string;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.iType           = otherOptions.iType;
        this.treeHandle      = otherOptions.treeHandle;
        this.fpaTypeCategory = otherOptions.fpaTypeCategory;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.iType) parameters += '<iType>' + this.iType + '</iType>';
        if (this.treeHandle) parameters += '<treeHandle>' + this.treeHandle + '</treeHandle>';
        if (this.fpaTypeCategory) parameters += '<fpaTypeCategory>' + this.fpaTypeCategory + '</fpaTypeCategory>';
        
        return super.wrapperXml('ListProjectTemplate', parameters);
    }
}
