import { useEffect, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Cell, Column, Row, TableView, TableBody, TableHeader, Flex, Heading, Content, View, Link, ProgressCircle, DialogContainer, Dialog } from '@adobe/react-spectrum';
import BillingLineAddEdit from './BillingLineAddEdit';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListProjectItemRequest } from '../../../../services/soap/item/requests/ListProjectItemRequest';
import { ListProjectItemRequestOptions_BillingItem } from '../../../../services/soap/item/requests/ListProjectItemRequestOptions';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useTranslation } from 'react-i18next';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { UpdateProjectItemRequest } from '../../../../services/soap/item/requests/UpdateProjectItemRequest';
import { generateBillingItem } from '../../../../services/soap/item/requests/CreateProjectItemOptions';
import { ToastQueue } from '@react-spectrum/toast';
import { GetItemRequest } from '../../../../services/soap/item/requests/GetItemRequest';
import { CreateProjectItemRequest } from '../../../../services/soap/item/requests/CreateProjectItemRequest';
import { DeleteProjectItemRequest } from '../../../../services/soap/item/requests/DeleteProjectItemRequest';
import useComonentReload from '../../../../hooks/UseComponentReload';
import styles from './BillingLineComponent.module.css';

interface BillingLineProps {
  isTotalPriceColumnVisible?: boolean;
  isUnitPriceColumnVisible?: boolean;
  minHeight?: number;
  percentWidth?: number;
  selectedItem?: FPAData;
}

export interface RowData {
  id: string;
  quantity: number;
  partID: string;
  _refId?: string;
  partNumber: string;
  productName: string;
  currency: string;
  unitPrice: number;
  amountWithVat: number;
  vatValue: number;
  totalPrice: string;
  totalPriceWithVat: string;
}

function BillingLine({ isTotalPriceColumnVisible = true, isUnitPriceColumnVisible = true, selectedItem }: BillingLineProps) {
  const { t } = useTranslation();
  let columnsData = [
    { name: t('quantity', { ns: 'layout_components' }), key: 'quantity', width: 100 },
    { name: t('part_number', { ns: 'layout_components' }), key: 'partNumber' },
    { name: t('product_name', { ns: 'layout_components' }), key: 'productName' },
    { name: t('unit_price', { ns: 'layout_components' }), key: 'unitPriceCurr' },
    { name: t('total_price', { ns: 'layout_components' }), key: 'totalPriceCurr' },
  ];

  let columnsDataMobile = [
    { name: t('quantity', { ns: 'layout_components' }), key: 'quantity', width: 100 },
    { name: t('part_number', { ns: 'layout_components' }), key: 'partNumber', width: 120 },
    { name: t('product_name', { ns: 'layout_components' }), key: 'productName', width: 250 },
    { name: t('unit_price', { ns: 'layout_components' }), key: 'unitPriceCurr', width: 130 },
    { name: t('total_price', { ns: 'layout_components' }), key: 'totalPriceCurr', width: 130 },
  ];

  if (!isUnitPriceColumnVisible) {
    columnsData = columnsData.filter(column => column.key !== 'unitPriceCurr');
    columnsDataMobile = columnsDataMobile.filter(column => column.key !== 'unitPriceCurr');
  }

  if (!isTotalPriceColumnVisible) {
    columnsData = columnsData.filter(column => column.key !== 'totalPriceCurr');
    columnsDataMobile = columnsDataMobile.filter(column => column.key !== 'totalPriceCurr');
  }

  let defaultRowData: RowData = {
    id: '0',
    quantity: 1,
    partID: '',
    _refId: '',
    partNumber: '',
    productName: '',
    currency: '',
    unitPrice: 0,
    amountWithVat: 0,
    vatValue: 0,
    totalPrice: '0',
    totalPriceWithVat: '0',
  };

  const [reloadComponent] = useComonentReload();
  const { itemService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const [billingLineData, setBillingLineData] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<RowData>(defaultRowData);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showAddEditRow, setShowAddEditRow] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    //console.log('isTotalPriceColumnVisible:', isTotalPriceColumnVisible);
    //console.log('isUnitPriceColumnVisible:', isUnitPriceColumnVisible);
    setShowAddEditRow(false);
    (async () => {
      setShowLoader(true);
      await loadBillingLineData();
      setShowLoader(false);
    })();
  }, [selectedItem, reloadComponent]);

  const updateBillingLineData = async (billingLineData: any[]) => {
    if (!billingLineData) return [];
    return Promise.all(
      billingLineData.map(async item => {
        const partNumber = await getPartNumber(item);
        return { ...item, _refId: `${partNumber}_${item.productName}_ref`, partNumber };
      })
    );
  };

  const formatDecimalToString = (amount: any) => {
    // Check if the amount ends with '.00'
    if (amount.endsWith('.00')) {
      // If yes, remove the decimal part
      return amount.substring(0, amount.length - 3);
    }
    // If the decimal part is not '.00', return the original amount
    return amount;
  };

  // Function to load billing line data
  const loadBillingLineData = async () => {
    // Retrieve selected item
    //console.log('selectedItem', selectedItem);

    // Call API to fetch billing line data
    let billingRep: any = await itemService.listProjectItem(new ListProjectItemRequest(store.Server, store.SessionId, 50, 0, new ListProjectItemRequestOptions_BillingItem(String(selectedItem?.id))));
    //  console.log('billingRep', billingRep);

    // Set billing line data state
    setBillingLineData(billingRep);

    // If API call is successful
    if (billingRep?.result === 'OK') {
      // Map the billing line data to desired format
      let billingLineData = billingRep?.ROWS?.map((item: any) => {
        return {
          id: item.ITEM.id,
          quantity: formatDecimalToString(Number.parseFloat(item.ITEM.quantity).toFixed(2)),
          partID: item.ITEM.item,
          _refId: '',
          partNumber: 'Part Number',
          productName: item.ITEM.name,
          currency: item.ITEM.currency,
          vatValue: item.ITEM.vatValue,
          amountWithVat: item.ITEM.amountWithVat,
          unitPrice: formatDecimalToString(item.ITEM.amount),
          unitPriceCurr: formatDecimalToString(item.ITEM.amount) + ' ' + item.ITEM.currency,
          totalPrice: formatDecimalToString(Number.parseFloat(item.ITEM.totalAmount).toFixed(2)),
          totalPriceCurr: formatDecimalToString(Number.parseFloat(item.ITEM.totalAmount).toFixed(2)) + ' ' + item.ITEM.currency,
          purchasePrice: item.ITEM.purchasePrice,
          totalAmountWithVat: item.ITEM.totalAmountWithVat,
        };
      });

      // Update billing line data with additional information
      updateBillingLineData(billingLineData).then((updatedData: any) => {
        //console.log('updatedData', updatedData);
        setRowsData(updatedData);
        //console.log('rowsData', rowsData);
      });
    }
  };

  const getPartNumber = async (item: any) => {
    //console.log('item', item);
    let itemResp = await itemService.getItem(new GetItemRequest(store.Server, store.SessionId, item.partID));
    //console.log('itemResp', itemResp);
    return itemResp.ITEM.refId;
  };

  const handleRowClick = (rowId: any): void => {
    //const isSelectedRowNotEmpty = selectedRow && Object.keys(selectedRow).length > 0;
    //console.log('rowId', rowId);
    const rowData: any = rowsData.find((row: any) => row.id === rowId.currentKey);
    //console.log('rowData', rowData);
    setSelectedRow(rowData);
    setShowAddEditRow(true);
    isMobile && setIsOpen(true);
    setSelectedKeys(rowId);
  };

  const handleRowClose = (): void => {
    setSelectedRow(defaultRowData);
    setShowAddEditRow(false);
    isMobile && setIsOpen(false);
    handleUnselect();
  };

  const handleUnselect = () => {
    setSelectedKeys(new Set());
  };

  const addRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('record', record);

      let result = await itemService.createProjectItem(
        new CreateProjectItemRequest(
          store.Server,
          store.SessionId,
          generateBillingItem(
            String(selectedItem?.parent_id),
            String(selectedItem?.id),
            record.partID,
            record.productName,
            record.quantity,
            record.unitPrice,
            record.totalPrice,
            record.totalPriceWithVat || 0,
            record.currency,
            record.vatValue || 0,
            record.purchasePrice
          )
        )
      );

      // console.log('result', result);
      if (result.result === 'OK') {
        await loadBillingLineData();
        handleRowClose();
        ToastQueue.positive('Record saved successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error: any) {
      ToastQueue.negative(error.message, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const updateRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('update record', record);
      let itemRow = billingLineData.ROWS.find((row: any) => row.ITEM.id == record.id);
      //console.log('itemRow', itemRow);
      let result = await itemService.updateProjectItem(
        new UpdateProjectItemRequest(
          store.Server,
          store.SessionId,
          generateBillingItem(
            String(selectedItem?.parent_id),
            String(selectedItem?.id),
            record.partID,
            record.productName,
            record.quantity,
            record.unitPrice,
            record.totalPrice,
            record.totalAmountWithVat || 0,
            record.currency,
            record.vatValue || 0,
            record.purchasePrice,
            itemRow.ITEM
          )
        )
      );

      // console.log('result', result);
      if (result.result === 'OK') {
        await loadBillingLineData();
        handleRowClose();
        ToastQueue.positive(t('record_updated_successfully', { ns: 'layout_components' }), { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const deleteRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('record', record);

      let result = await itemService.deleteProjectItem(new DeleteProjectItemRequest(store.Server, store.SessionId, record.id));

      // console.log('result', result);
      if (result.result === 'OK') {
        await loadBillingLineData();
        handleRowClose();
        ToastQueue.positive('Record deleted successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            {t('material_billing', { ns: 'layout_components' })}
          </Content>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <Link
                isQuiet
                onPress={e => {
                  setSelectedRow(defaultRowData);
                  handleUnselect();
                  isMobile && setIsOpen(true);
                  setShowAddEditRow(true);
                }}
              >
                <i className="bi bi-plus fs-5">
                  <View UNSAFE_className={styles.icon_add_text}>{t('add_material', { ns: 'layout_components' })}</View>
                </i>
              </Link>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px', M: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            <TableView
              aria-label="Billing Line"
              onSelectionChange={handleRowClick}
              selectionMode="single"
              selectedKeys={selectedKeys}
              selectionStyle="highlight"
              width={{ base: '100%', L: '100%', M: '100%' }}
              minHeight={'80px'}
              maxHeight={{ base: '1000px', L: '450px' }}
              marginBottom={'size-250'}
            >
              <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                {column => (
                  <Column showDivider key={column.key} width={column?.width}>
                    {column.name}
                  </Column>
                )}
              </TableHeader>
              <TableBody items={rowsData}>{item => <Row>{columnKey => <Cell>{(item as any)[String(columnKey)]}</Cell>}</Row>}</TableBody>
            </TableView>
          </Flex>

          {isMobile ? (
            <DialogContainer
              isDismissable
              onDismiss={() => {
                setIsOpen(false);
              }}
            >
              {isOpen && (
                <Dialog>
                  <Heading>
                    {' '}
                    {selectedRow.id === '0' ? t('add', { ns: 'layout_components' }) : t('edit', { ns: 'layout_components' })} {t('material', { ns: 'layout_components' })}
                  </Heading>
                  <Content>
                    <BillingLineAddEdit
                      id={String(selectedItem?.id)}
                      defaultRowData={defaultRowData}
                      selectedRowData={selectedRow}
                      handleRowClose={handleRowClose}
                      addRecord={addRecord}
                      updateRecord={updateRecord}
                      deleteRecord={deleteRecord}
                      isUnitPriceColumnVisible={isUnitPriceColumnVisible}
                      isTotalPriceColumnVisible={isTotalPriceColumnVisible}
                      closeDialog={closeDialog}
                      styles={styles}
                    />
                  </Content>
                </Dialog>
              )}
            </DialogContainer>
          ) : (
            showAddEditRow && (
              <BillingLineAddEdit
                id={String(selectedItem?.id)}
                defaultRowData={defaultRowData}
                selectedRowData={selectedRow}
                handleRowClose={handleRowClose}
                addRecord={addRecord}
                updateRecord={updateRecord}
                deleteRecord={deleteRecord}
                isUnitPriceColumnVisible={isUnitPriceColumnVisible}
                isTotalPriceColumnVisible={isTotalPriceColumnVisible}
                closeDialog={closeDialog}
                styles={styles}
              />
            )
          )}
        </Flex>
      </Flex>
    );
  }
}

export const BillingLineComponent = LayoutComponent(BillingLine);
