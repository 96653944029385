import React, { useEffect, useState } from 'react';
import { Column, Flex, Link, ProgressCircle, TableBody, Row, Cell, TableHeader, TableView, TextField, View } from '@adobe/react-spectrum';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../../hooks/UseViewInfo';
import TabsComponent from './TabsComponent';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import { GetContactListRequest } from '../../../../../services/soap/main/requests/GetContactListRequest';
import { GetFolderRequest } from '../../../../../services/soap/project/requests/GetFolderRequest';
import { GetProjectRequest } from '../../../../../services/soap/project/requests/GetProjectRequest';
import { GetActivityRequest } from '../../../../../services/soap/project/requests/GetActivityRequest';
import { useDebounce } from '@uidotdev/usehooks';
import { FPADataTypes } from '../../../../../infra/protected/FPA/FPAData';
import { UpdateActivityRequest } from '../../../../../services/soap/project/requests/UpdateActivityRequest';
import { UpdateProjectRequest } from '../../../../../services/soap/project/requests/UpdateProjectRequest';
import { UpdateFolderRequest } from '../../../../../services/soap/project/requests/UpdateFolderRequest';
import styles from './ExternalContactsSearch.module.css';

interface ExternalContactsSearchProps {
  openDialog: (isOpen: boolean) => void;
  closeDialog: (reload: boolean) => void;
  currentItem: any;
  selectedItem: any;
}

interface Character {
  name: any;
  email: any;
  phone: any;
}

type UpdateRequestFunction = () => Promise<{ result: string }>;

const ExternalContactsSearch: React.FC<ExternalContactsSearchProps> = ({ openDialog, closeDialog, currentItem, selectedItem }) => {
  const { isMobile } = useViewInfo();
  const { mainService, projectService, store } = useDependency();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [contactRecord, setContactRecord] = useState<any>(undefined);
  const [contextIdValue, setContextIdValue] = useState<any>(undefined);
  const [filterTerm, setFilterTerm] = useState<string>('');
  const debouncedFilterTerm = useDebounce(filterTerm, 1000);

  const PAGE_SIZE: number = 50;
  const { t } = useTranslation();
  let columnsData = [
    { name: t('name', { ns: 'layout_components' }), key: 'name' },
    { name: t('email', { ns: 'layout_components' }), key: 'email' },
    { name: t('phone', { ns: 'layout_components' }), key: 'phone' },
  ];

  let columnsDataMobile = [
    { name: t('name', { ns: 'layout_components' }), key: 'name', width: 250 },
    { name: t('email', { ns: 'layout_components' }), key: 'email', width: 150 },
    { name: t('phone', { ns: 'layout_components' }), key: 'phone', width: 150 },
  ];

  useEffect(() => {
    (async () => {
      if (debouncedFilterTerm) {
        setShowLoader(true);

        let contactListResp: any = await mainService.getContactList(
          new GetContactListRequest(
            store.Server,
            store.SessionId,
            {
              offset: 0,
              limit: PAGE_SIZE,
              contains: '*' + filterTerm
            }
          )
        );

        let contactResp = contactListResp?.CONTACTS;
        //console.log('contactResp', contactResp);
        let contactData = contactResp?.CONTACT.map((contact: any) => ({
          ...contact,
          name: contact?.name1 + ' ' + contact?.name3,
          email: contact?.email ?? '',
          phone: contact?.phone ?? '',
        }));

        setContactRecord(contactData);
        setShowLoader(false);
      }
    })();
  }, [debouncedFilterTerm]);

  const loadContactData = async (typeName: any, contextId: any) => {
    setShowLoader(true);
    setContactRecord([]);
    setContextIdValue(contextId);
    setFilterTerm('');
    setSelectedRow({});
    setSelectedKeys(new Set());

    let contactResp: any;
    switch (typeName) {
      case 'folder':
        {
          let folderResp = await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, contextId));
          contactResp = folderResp.FOLDER?.EXTERNAL_CONTACTS;
        }
        break;

      case 'project':
        {
          let projectResp = await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, contextId));
          contactResp = projectResp.PROJECT?.EXTERNAL_CONTACTS;
        }
        break;

      case 'activity':
        {
          let activityResp = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, contextId));
          contactResp =
            activityResp.ACTIVITY?.EXTERNAL_CONTACTS && typeof activityResp.ACTIVITY.EXTERNAL_CONTACTS.count === 'number' && activityResp.ACTIVITY.EXTERNAL_CONTACTS.count > 0
              ? activityResp.ACTIVITY.EXTERNAL_CONTACTS
              : {
                  count: '0',
                  CONTACT: [],
                };
        }
        break;

      default:
        setContextIdValue(contextId);
        setContactRecord(undefined);
        break;
    }

    let contactData;
    if (typeName === 'folder' || typeName === 'project') {
      contactData = contactResp?.ROWS?.map((contact: any) => ({
        ...contact.EXTERNAL_CONTACT,
        name: contact.EXTERNAL_CONTACT?.name1 + ' ' + contact.EXTERNAL_CONTACT?.name3,
        email: contact.EXTERNAL_CONTACT?.email ?? '',
        phone: contact.EXTERNAL_CONTACT?.phone ?? '',
      }));
    } else if (typeName === 'activity') {
      contactData = contactResp?.CONTACT?.map((contact: any) => ({
        ...contact.EXTERNAL_CONTACT,
        name: contact.EXTERNAL_CONTACT?.name1 + ' ' + contact.EXTERNAL_CONTACT?.name3,
        email: contact.EXTERNAL_CONTACT?.email ?? '',
        phone: contact.EXTERNAL_CONTACT?.phone ?? '',
      }));
    }

    setContactRecord(contactData);
    setShowLoader(false);
  };

  const handleRowClick = (rowId: any): void => {
    const rowData: any = contactRecord.find((row: any) => row.id === rowId.currentKey);
    setSelectedRow(rowData);
    setSelectedKeys(rowId);
  };

  const handleFiltering = async (e: any) => {
    setFilterTerm(e);
  };

  const NewContactButton = () => (
    <Flex UNSAFE_className={styles.button_container_contact_search_second}>
      <i className={`${styles.icon_add} bi bi-plus fs-5`} />
      <Link isQuiet UNSAFE_className={styles.new_contact_text} onPress={() => openDialog(true)}>
        {isMobile ? t('new', { ns: 'layout_components' }) : t('new_contact', { ns: 'layout_components' })}
      </Link>
    </Flex>
  );

  const ContactActions = () => (
    <Flex UNSAFE_className={styles.button_container_contact_search}>
      <Flex UNSAFE_className={styles.contact_search_cancel}>
        <Link isQuiet UNSAFE_className={styles.contact_search_cancel_text} onPress={() => closeDialog(false)}>
          {t('cancel', { ns: 'layout_components' })}
        </Link>
      </Flex>
      {selectedRow?.id && (
        <Flex UNSAFE_className={styles.contact_search_add}>
          <Link isQuiet UNSAFE_className={styles.contact_search_add_text} onPress={onAddContact}>
            {t('add_contact', { ns: 'layout_components' })}
          </Link>
        </Flex>
      )}
    </Flex>
  );

  const ContactButtons = () => (
    <Flex direction={isMobile ? 'column' : { base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-100'}>
      <NewContactButton />
      <ContactActions />
    </Flex>
  );

  const updateItem = async (updateRequest: UpdateRequestFunction, successMessage: string) => {
    const updatedItem = await updateRequest();
    if (updatedItem.result === 'OK') {
      ToastQueue.positive(successMessage, { timeout: 3000 });
    }
  };

  const onAddContact = async () => {
    if (!selectedRow) return;
    //console.log('selectedRow', selectedRow);
    const requestData = {
      server: store.Server,
      sessionId: store.SessionId,
      data: {
        ...currentItem.current,
        EXTERNAL_CONTACTS: {
          count: 1,
          ROWS: selectedRow.contextId
            ? [{ EXTERNAL_CONTACT: { ...selectedRow, note: '', deleteContact: '0' } }]
            : [
                {
                  EXTERNAL_CONTACT: {
                    contactId: selectedRow.id,
                    note: '',
                    isPrimary: 0,
                    role: '',
                    relationshipLevel: '',
                    deleteContact: 0,
                  },
                },
              ],
        },
      },
    };

    let successMessage = t('contact_added_successfully', { ns: 'layout_components' });

    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        await updateItem(() => projectService.updateActivity(new UpdateActivityRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.PROJECT:
        await updateItem(() => projectService.updateProject(new UpdateProjectRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.FOLDER:
        await updateItem(() => projectService.updateFolder(new UpdateFolderRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
    }

    closeDialog(true);
  };

  const renderCellContent = (columnKey: string, content: any) => {
    const iconStyles = { color: '#B0BEC5', WebkitTextStroke: '.5px' };
    const icons: { [key: string]: string } = {
      email: 'bi bi-envelope fs-7',
      phone: 'bi bi-telephone fs-7',
    };

    return (
      <>
        {icons[columnKey] && <i className={icons[columnKey]} style={iconStyles}></i>}&nbsp;{content}
      </>
    );
  };

  return (
    <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'} UNSAFE_style={{ overflowY: 'auto' }}>
      <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'}>
        <View UNSAFE_className={styles.add_contact_parent}>
          <View UNSAFE_className={styles.add_contact_child}></View>
          <View UNSAFE_className={styles.contact_list_heading}>{t('contact_list', { ns: 'layout_components' })}</View>
          <Flex UNSAFE_className={styles.contact_search_parent}>
            <Flex UNSAFE_className={!contextIdValue ? styles.contact_search_child : styles.contact_search_child_disabled}>
              <TextField isDisabled={contextIdValue} value={filterTerm} width={'100%'} onChange={handleFiltering} />
              <i className={`${styles.icon_contact_search} bi bi-search`}></i>
            </Flex>
          </Flex>

          <TabsComponent loadContactData={loadContactData} selectedItem={selectedItem} />
          <Flex UNSAFE_className={styles.contacts_search_result}>
            <Flex maxHeight={{ base: '1000px', L: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
              {showLoader ? (
                <Flex width="100%" justifyContent={'center'} marginTop={10}>
                  <ProgressCircle aria-label="Loading…" isIndeterminate />
                </Flex>
              ) : (
                contactRecord && (
                  <TableView
                    aria-label="contact list"
                    onSelectionChange={handleRowClick}
                    selectionMode="single"
                    selectedKeys={selectedKeys}
                    selectionStyle="highlight"
                    width={{ base: '100%', L: '100%', M: '100%' }}
                    minHeight={'80px'}
                    maxHeight={{ base: '1000px', L: '450px' }}
                    marginBottom={'size-250'}
                    density="compact"
                    isQuiet
                    renderEmptyState={() => <div>{t('no_contact_found', { ns: 'layout_components' })}</div>}
                  >
                    <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                      {(column: any) => (
                        <Column showDivider width={column?.width}>
                          {column.name}
                        </Column>
                      )}
                    </TableHeader>
                    <TableBody items={contactRecord}>{(item: any) => <Row>{(columnKey: any) => <Cell>{renderCellContent(columnKey, item[columnKey])}</Cell>}</Row>}</TableBody>
                  </TableView>
                )
              )}
            </Flex>
          </Flex>
          <View UNSAFE_className={styles.footer}>
            <ContactButtons />
          </View>
        </View>
      </Flex>
    </Flex>
  );
};

export default ExternalContactsSearch;
